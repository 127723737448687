import {
    getKenticoCode, getPlateSize, getPlateColor, getTextColor, getMessageColors, getCaptionPosition, getPlateFormat, getVehicleType, getDealerOnly, getAuthorizedDealers
} from '@utils/Helpers'

import {
    PlateColor, ProductVariant, ProductModel, ProductModelCategory, ProductModelCategoryList, Brand, PlateCategoryMetaInfo, VehicleType, VehicleTypesSetting
} from "@models/ProductModels"

import { KenticoChoice } from '@utils/KontentTypes'
import { PlateDesignFormats } from '@utils/Constant'
import { PlateSizeGuideModel } from '@models/ProductModels/PlateHoleGuideModel'

type MappedProductDesignModel = {
    designName: string
    disableDualSizing: boolean
    transactionTypes: KenticoChoice[]
    vehicleType: KenticoChoice
    plateDesignCode: KenticoChoice
    plateTextColor: KenticoChoice
    plateSize: KenticoChoice
    plateColor: KenticoChoice
    basePrice: number
    redesignPrice: number
    upgradePrice: number
    caption?: KenticoChoice
    captionMaxLength?: number,
    messageColors?: KenticoChoice[]
    brand?: Brand
    dualPlate?: MappedProductDesignModel
    dealerOnly: KenticoChoice
    authorizedDealers?: KenticoAuthorizedDealers,
    bottomCaptionMaxLength?: number,
}

export type KenticoAuthorizedDealers = {
    title: {
        value: string
    },
    link_message: {
        value: string
    },
    description: {
        value: string
    },
    dealer_contacts: {
        linked_items: {
            elements: {
                dealer_name: {
                    value: string
                },
                contact_name: {
                    value: string
                }
                phone: {
                    value: string
                }
                formatted_phone_number: {
                    value: string
                },
                dealer_id: {
                    value: string
                }
            }
        }[]
    }
}

export const mapToProductElements = (productElement: any) => {
    const { name, dealer_only_design, authorized_dealers, transaction_types, vehicle_types, kpi_plate_design_code, kpi_plate_foreground_color, plate_size, plate_color, base_price, redesign_price, govt_upgrade_price, captions, max_message_length__mm_, message_colors, european_brand_name, dual_size_plate, bottom_caption_max_length__mm_ } = productElement
    const result: MappedProductDesignModel = {
        designName: name.value,
        transactionTypes: transaction_types.value,
        vehicleType: vehicle_types.value[0],
        disableDualSizing: productElement.disable_dual_sizing.value.length > 0 ? true : false,
        plateDesignCode: kpi_plate_design_code.value[0],
        plateTextColor: kpi_plate_foreground_color.value[0],
        plateSize: plate_size.value[0],
        plateColor: plate_color.value[0],
        basePrice: base_price.value,
        redesignPrice: redesign_price.value,
        upgradePrice: govt_upgrade_price.value,
        caption: captions.value.length > 0 ? captions.value[0] : undefined,
        captionMaxLength: max_message_length__mm_.value,
        bottomCaptionMaxLength: bottom_caption_max_length__mm_?.value || undefined,
        messageColors: message_colors.value.length > 0 ? message_colors.value : undefined,
        brand: european_brand_name.value[0].codename === 'n_a' ? undefined : { name: european_brand_name.value[0].name, value: european_brand_name.value[0].codename },
        dualPlate: (dual_size_plate !== undefined && dual_size_plate.linked_items.length > 0) ? mapToProductElements(dual_size_plate.linked_items[0].elements) : undefined,
        dealerOnly: dealer_only_design.value[0],
        authorizedDealers: authorized_dealers.linked_items.length > 0 ? authorized_dealers.linked_items[0].elements : undefined
    }
    return result;
}

export const mapToProductModelCategory = (item: MappedProductDesignModel): ProductModelCategory => {
    const plateColor: PlateColor = getPlateColor(item.plateColor.name, item.plateColor.codename);
    let productVariant: ProductVariant = {
        textColor: getTextColor(item.plateTextColor),
        dualProduct: item.dualPlate === undefined ? undefined : new ProductModel(
            item.dualPlate.designName,
            +getKenticoCode(item.dualPlate.plateDesignCode.codename),
            getPlateSize(item.dualPlate.plateSize.name, item.dualPlate.plateSize.codename),
            undefined,
            item.dualPlate.caption === undefined ? undefined : getCaptionPosition(item.dualPlate.caption),
            item.captionMaxLength,
            item.dualPlate.messageColors === undefined ? undefined : getMessageColors(item.dualPlate.messageColors),
            getVehicleType(item.dualPlate.vehicleType),
            getDealerOnly(item.dualPlate.dealerOnly),
            item.authorizedDealers === undefined ? undefined : getAuthorizedDealers(item.authorizedDealers),
            item.disableDualSizing,
            item.bottomCaptionMaxLength
        )
    }
    var productModel: ProductModel = new ProductModel(
        item.designName,
        +getKenticoCode(item.plateDesignCode.codename),
        getPlateSize(item.plateSize.name, item.plateSize.codename),
        [productVariant],
        item.caption === undefined ? undefined : getCaptionPosition(item.caption),
        item.captionMaxLength,
        item.messageColors === undefined ? undefined : getMessageColors(item.messageColors),
        getVehicleType(item.vehicleType),
        getDealerOnly(item.dealerOnly),
        item.authorizedDealers === undefined ? undefined : getAuthorizedDealers(item.authorizedDealers),
        item.disableDualSizing,
        item.bottomCaptionMaxLength
    )
    const productModelCategory = new ProductModelCategory(plateColor, [productModel], item.brand);
    return productModelCategory;
}

type KenticoProductDesignsCategories = Array<{
    elements: object
}>

export const mapToProductModelCategoryList = (name: string, categories: KenticoProductDesignsCategories, vehicleType: VehicleType): ProductModelCategoryList => {
    const categoryList = categories.map(c => mapToProductElements(c.elements));

    let categoryModels: ProductModelCategory[] = [];
    categoryList.forEach(cl => {
        const vt = getVehicleType(cl.vehicleType);
        if (vt.name === vehicleType.name) {
            categoryModels.push(mapToProductModelCategory(cl));
        }
    });
    let result: ProductModelCategoryList = new ProductModelCategoryList(name, [], []);
    categoryModels.forEach(element => {
        result.add(element);
    });
    return result;
}

const categoryAvailableForVehicleType = (element: any, vehicleType: VehicleType) => {
    if (element.vehicle_types.value !== undefined && element.vehicle_types.value.length > 0) {
        const availableVehicleTypes = element.vehicle_types.value;
        const isAvailable = availableVehicleTypes.find((vt: KenticoChoice) => vt.codename.toUpperCase() === vehicleType.name.toUpperCase()) !== undefined;
        return isAvailable;
    }
    return false;
}

export const mapToPlateDesignList = (elements: Array<any>, combination: string, vehicleType?: VehicleType) => {
    const vt = vehicleType === undefined ? VehicleTypesSetting.Car : vehicleType;
    let result: { metaInfo: PlateCategoryMetaInfo, products: ProductModelCategoryList }[] = [];
    elements.forEach((element) => {
        const isAvailable = categoryAvailableForVehicleType(element.elements, vt);
        if (isAvailable) {
            const metaInfo: PlateCategoryMetaInfo = calculatePlateMetaInfo(element.elements, combination);
            const products = mapToProductModelCategoryList(metaInfo.name, element.elements.product_designs.linked_items, vt);
            result.push({
                metaInfo: metaInfo,
                products: products
            });
        }
    })
    return result;
}

export const mapToPlateDesignGroupList = (els: Array<any>, combination: string, vehicleType?: VehicleType) => {
    let results: Array<any> = []
    els.forEach((el) => {
        var elements: Array<any> = el.elements.group_name.linked_items;
        var gName = el.elements.group_name_to_display.value;
        var designCounts = 0;   
        var groupeAvailable = false;

        const vt = vehicleType === undefined ? VehicleTypesSetting.Car : vehicleType;
        let result: { metaInfo: PlateCategoryMetaInfo, products: ProductModelCategoryList}[] = [];
        elements.forEach((element) => {
            const isAvailable = categoryAvailableForVehicleType(element.elements, vt);
            if (isAvailable) {
                groupeAvailable = true
                const metaInfo: PlateCategoryMetaInfo = calculatePlateMetaInfo(element.elements, combination);
                const products = mapToProductModelCategoryList(metaInfo.name, element.elements.product_designs.linked_items, vt);
                result.push({
                    metaInfo: metaInfo,
                    products: products,
                });
                designCounts++;
            }
        })
        if (groupeAvailable){
            var data: any = {
                groupName: gName,
                items: result,
                designCounts: designCounts
            }
            results.push(data)
        }        
    })
    return results;
}

export const mapToPlateDesignListMobile = (elements: Array<any>, combination: string, vehicleType?: VehicleType) => {
    const vt = vehicleType === undefined ? VehicleTypesSetting.Car : vehicleType;
    let result: { metaInfo: PlateCategoryMetaInfo, products: ProductModelCategoryList, vehicleTpes: KenticoChoice[], plateSizes: KenticoChoice[] }[] = [];
    let designList : {}[] = []
    elements.forEach((element) => {
        element.elements.group_name.linked_items.forEach((element: { elements: {} }) => {
            designList.push(element)
        })
    })
    designList.forEach((element) => {
        const isAvailable = categoryAvailableForVehicleType(element.elements, vt);
        if (isAvailable) {
            const metaInfo: PlateCategoryMetaInfo = calculatePlateMetaInfo(element.elements, combination);
            const products = mapToProductModelCategoryList(metaInfo.name, element.elements.product_designs.linked_items, vt);
            const vehicleTpes = element.elements.vehicle_types.value;
            const plateSizes = element.elements.plate_sizes.value;
            result.push({
                metaInfo: metaInfo,
                products: products,
                vehicleTpes: vehicleTpes,
                plateSizes: plateSizes
            });
        }
    })
    return result;
}

export const calculatePlateMetaInfo = (element: any, formattedCombination: string): PlateCategoryMetaInfo => {
    const startingPrice = calculatePlateDesignStartingPrice(element, formattedCombination);

    const lockedMessageColor = element.lock_caption_colors && element.lock_caption_colors.value.length > 0 && element.lock_caption_colors.value[0].codename === "true";
    return {
        startingPrice: startingPrice,
        name: element.category_name.value,
        description: element.category_description_rich.value,
        featureImage: element.feature_image?.value[0]?.url || '',
        isNewDesign: element.new_design.value[0].name,
        url: element.url ? element.url.value : '',
        featureImageMobile: element.feature_image_mobile?.value[0]?.url || '',
        lockedMessageColor: lockedMessageColor
    };
}
export const calculatePlateDesignStartingPrice = (element: any, formattedCombination: string) => {
    const combination = formattedCombination.toString().split(' ').join('');
    const plateFormat = getPlateFormat(combination);
    let startingPrice = 0;
    if (plateFormat === PlateDesignFormats.Fixed) {
        startingPrice = element.fixed_fomat_price.value
    } else if (plateFormat === PlateDesignFormats.Flexi) {
        startingPrice = element.flexi_format_price.value;
    } else if (plateFormat === PlateDesignFormats.Premium3) {
        startingPrice = element.premium_3_character_price.value;
    } else {
        startingPrice = element.premium_2_character_price.value;
    }
    const upgrade = element.upgrade_starting_price.value;
    const redesign = element.redesign_starting_price.value;
    return {
        new: startingPrice,
        upgrade: upgrade,
        redesign: redesign
    };
}
export const mapToPlateHoleGuidePlates = (kenticoPlates: any): PlateSizeGuideModel[] => {
    let plateModels: PlateSizeGuideModel[] = [];

    kenticoPlates.forEach((kenticoPlate: any) => {
        plateModels.push({
            descripton: kenticoPlate.elements.description.value,
            plate_image_url: kenticoPlate.elements.plate_image.value[0].url,
            size_height: kenticoPlate.elements.size_height.value,
            size_width: kenticoPlate.elements.size_width.value,
            title: kenticoPlate.elements.title.value
        })
    });

    return plateModels;
}